import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

let modalsShowing = 0;

function modalWillShow() {
  if (modalsShowing === 0 && document) {
    document.body.classList.add('modal-open');
  }

  modalsShowing += 1;
}

function modalWillHide() {
  modalsShowing -= 1;

  if (modalsShowing === 0 && document) {
    document.body.classList.remove('modal-open');
  }
}

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      modalIndex: 0,
    };
  }

  componentDidMount = () => {
    if (this.props.show) {
      modalWillShow();
    }
  };

  // Shenanigans to allow the CSS fade to happen before we stop rendering the dialog or divs
  componentDidUpdate = (prevProps) => {
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        modalWillShow();
      } else {
        modalWillHide();
      }

      if (this.props.fade) {
        this.setState(
          { transitioning: true, modalIndex: modalsShowing },
          () => {
            window.setTimeout(() => {
              this.setState({ show: this.props.show }, () => {
                window.setTimeout(() => {
                  this.setState({ transitioning: false });
                }, 150);
              });
            }, 16); // I don't like this magic number but I haven't found a better way
          },
        );
      } else {
        this.setState({ show: this.props.show });
      }
    }
  };

  componentWillUnmount = () => {
    if (this.props.show) {
      modalWillHide();
    }
  };

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  renderBackdrop = () => {
    if (this.state.show || this.state.transitioning) {
      return (
        <div
          className={classNames('modal-backdrop', {
            show: this.state.show,
            fade: this.props.fade,
          })}
          onClick={this.props.onCloseClick}
          role="presentation"
          style={{ zIndex: 1040 + this.state.modalIndex }}
        />
      );
    }

    return null;
  };

  render = () => {
    const {
      wrapperProps,
      className,
      dialogClassName,
      onCloseClick,
      children,
      fade,
      ...other
    } = this.props;

    return (
      <div {...wrapperProps}>
        <div
          className={classNames(
            'modal',
            { show: this.state.show?.toString(), fade: fade },
            className,
          )}
          style={{
            display:
              this.state.show || this.state.transitioning ? 'block' : 'none',
            zIndex: 1040 + this.state.modalIndex + 1,
          }}
          role="dialog"
          aria-hidden={!this.state.show}
          tabIndex="-1"
          onClick={onCloseClick}
          {...other}
        >
          <div
            className={classNames(
              'modal-dialog modal-dialog-centered',
              dialogClassName,
            )}
            role="document"
            onClick={this.stopPropagation}
          >
            <div className="modal-content">{children}</div>
          </div>
        </div>
        {this.renderBackdrop()}
      </div>
    );
  };
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseClick: PropTypes.func,
  show: PropTypes.bool.isRequired,
  wrapperProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  fade: PropTypes.bool,
};

Modal.defaultProps = {
  onCloseClick: null,
  wrapperProps: null,
  className: null,
  dialogClassName: null,
  fade: true,
};

export default Modal;
